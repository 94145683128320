import * as React from "react"
import styled from "styled-components"
import { H1, H3, H5, H4, H6 } from "../theme/elements"

const Container = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1d490;
  border-top: 1px solid #f1d490;
  box-shadow: 0px 0 20px -4px rgba(0, 0, 0, 0.1);

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: column;
    margin-top: 60px;
  }
`

const InnerInfo = styled.div`
  width: 50%;
  background: #ffe6b0;
  height: 550px;
  padding: 0 8rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    width: 100%;
    height: 500px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.mobileL} {
    padding: 0 2rem;
  }
`

const InnerPhoto = styled.img`
  width: 50%;
  height: 50vh;
  object-position: right;
  object-fit: cover;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    width: 100%;
    height: 40vh;
  }
`

const Iframe = styled.iframe`
  width: 50%;
  height: 550px;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    width: 100%;
    height: 500px;
  }
`

export const SplitSection = () => {
  return (
    <Container id="contact-section">
      <InnerInfo>
        <H1>Adres</H1>
        <H3>Rijnstraat 6</H3>
        <H3>3441 BT, Woerden</H3>
        <H3>+31 657 597 880</H3>
        <H3>info@atelierstopler.nl</H3>
        ...
        <H4>Openingstijden</H4>
        <H6>Dinsdag: 10.00 - 17.00</H6>
        <H6>Woensdag: 10.00 - 17.00</H6>
        <H6>Vrijdag: 10.00 - 17.00</H6>
        <H6>Zaterdag: 10.00 - 17.00</H6>
      </InnerInfo>
      <Iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1225.7927347263135!2d4.882828384035146!3d52.08727557695423!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c679d773d1c0a7%3A0x6500350b17319211!2sRijnstraat%206%2C%203441%20BT%20Woerden%2C%20Netherlands!5e0!3m2!1sen!2sus!4v1675032038109!5m2!1sen!2sus"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></Iframe>
    </Container>
  )
}

export default SplitSection
