import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: ${({ theme }) => theme.fontWeight.normal}
    color: ${({ theme }) => theme.colors.black};
  }

  body {
    background: ${({ theme }) => theme.colors.white};
    line-height: ${({ theme }) => theme.lineHeight};
    letter-spacing: ${({ theme }) => theme.letterSpacing};
  }
`

export default GlobalStyle
