import * as React from "react"
import styled from "styled-components"

const Container = styled.div<{ isOpen: boolean }>`
  height: 40px;
  width: 40px;
  background: ${({ theme }) => theme.colors.accentColor};
  cursor: pointer;
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: ${({ isOpen }) => (isOpen ? "5px" : 0)};
  flex-direction: column;
`
const Bar = styled.div`
  height: 2px;
  width: 20px;
  background: ${({ theme }) => theme.colors.lightestGray};
  border-radius: 1px;
`
const ClosedBarOne = styled.div<{ isOpen: boolean }>`
  height: 2px;
  width: 20px;
  background: ${({ theme }) => theme.colors.lightestGray};
  border-radius: 1px;
  transform: rotate(45deg);
  position: ${({ isOpen }) => (isOpen ? "absolute" : "relative")};
`
const ClosedBarTwo = styled.div<{ isOpen: boolean }>`
  height: 2px;
  width: 20px;
  background: ${({ theme }) => theme.colors.lightestGray};
  border-radius: 1px;
  transform: rotate(-45deg);
  position: ${({ isOpen }) => (isOpen ? "absolute" : "relative")};
`

type Props = {
  handleMenu: () => void
  isOpen: boolean
}

export const MenuButton = ({ handleMenu, isOpen }: Props) => {
  return (
    <Container onClick={handleMenu} isOpen={true}>
      {isOpen ? (
        <>
          <ClosedBarOne isOpen={isOpen} />
          <ClosedBarTwo isOpen={isOpen} />
        </>
      ) : (
        <>
          <Bar />
          <Bar />
          <Bar />
        </>
      )}
    </Container>
  )
}

export default MenuButton
