import * as React from "react"
import styled from "styled-components"
import { ReactChild } from "react"
import { H2 } from "../theme/elements"

const Container = styled.div`
  padding: 30px;
  margin: 60px auto 100px auto;

  @media screen and ${({ theme }) => theme.breakpoints.mobileL} {
    padding: 0 20px;
  }
`
const Headline = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding: 20px 0;
`

type Props = {
  title?: string
  children: ReactChild
}

export const Section = ({ title, children }: Props) => {
  return (
    <Container>
      {title && (
        <Headline>
          <H2>{title}</H2>
        </Headline>
      )}
      {children}
    </Container>
  )
}

export default Section
