const colors = {
  textPrimary: "#141414",
  background: "#FFFFFF",
  white: "#FFFFFF",
  accentColorLight: "#F6E0CA",
  lightestGray: "#DCDCDC",
  accentBackground: "#5D6D80",
  textGray: "#6b6b6b",
  black: "#141414",
  accentColor: "#2C2C2C",
}

export default colors
