import * as React from "react"
import styled from "styled-components"
import { P } from "../theme/elements"
import atelierLogo from "../assets/photos/atelier-logo.png"

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: fit-content;
  padding: 0 25px 20px 25px;
`

const Info = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
`

const ContactText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  max-width: 500px;
  width: 100%;
`

const Base = styled.div`
  width: 100%;
  text-align: center;
  height: fit-content;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`

const Links = styled(P)`
  color: ${({ theme }) => theme.colors.textGray};
  border-bottom: 0.2rem solid white;
  text-decoration: none;

  &:hover {
    border-bottom: 0.2rem solid ${({ theme }) => theme.colors.accentBackground};
    cursor: pointer;
  }
`

const Socials = styled(P)`
  color: ${({ theme }) => theme.colors.black};
  background: #ffba93;
  border-radius: 2px;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
`

const BaseText = styled(P)`
  color: ${({ theme }) => theme.colors.black};
`

const Logo = styled.img`
  height: 18px;
  width: auto;
  margin-bottom: 4px;
`

export const Footer = () => {
  return (
    <Container>
      <Info>
        <ContactText>
          <Socials>Contact</Socials>
          <P>
            Bent u geïnteresseerd en wilt u meer informatie neem contact op.
            Alles is vrijblijvend! Zij vind het belangrijk om uw opdrachten met
            zorg uit te voeren, vandaar dat zij alleen op afspraak werkt. Bel
            voor een afspraak of stuur een mailtje. Mocht zij u niet meteen aan
            te woord kunnen staan dan belt zij u zo snel mogelijk terug.
          </P>
          <P>+31 657 597 880</P>
          <P>info@atelierstopler.nl</P>
        </ContactText>
      </Info>
      <Base>
        <Logo src={atelierLogo} alt="Atelier Stöpler logo" />
        <BaseText>Atelier Stöpler 2022</BaseText>
      </Base>
    </Container>
  )
}

export default Footer
