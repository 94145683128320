import * as React from "react"
import styled from "styled-components"
import MenuButton from "./MenuButton"
import { H5 } from "../theme/elements"
import atelier from "../assets/photos/atelier-logo.png"
import { Link } from "gatsby"

const Container = styled.div`
  background: white;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 20px -12px rgba(0, 0, 0, 0.5);

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    height: 60px;
  }

  @media screen and ${({ theme }) => theme.breakpoints.mobileL} {
    padding: 0 20px;
  }
`

const LeftSideContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const NavigationItems = styled.ul`
  display: flex;
  column-gap: 1.2rem;
  padding-left: 2rem;
  margin-top: 0.4rem;
  width: fit-content;
  align-items: center;
  list-style-type: none;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`

const Item = styled(Link)`
  padding: 1rem 0.1rem;
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.textGray};
  text-decoration: none;
  width: fit-content;
  border-bottom: 2px solid rgba(255, 255, 255, 0);

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
  }
`

const Img = styled.img`
  color: ${({ theme }) => theme.colors.textGray};
  height: 34px;
  width: auto;
`

const LogoText = styled(H5)`
  margin-top: 0.4rem;
  margin-left: 16px;
`

const CornerButton = styled.a`
  color: white;
  border: none;
  background: ${({ theme }) => theme.colors.accentColor};
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding: 0.6rem 1.2rem;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    display: none;
  }
`

const MenuButtonContainer = styled.div`
  display: none;

  @media screen and ${({ theme }) => theme.breakpoints.tablet} {
    display: inline-block;
  }
`

export const menuLinks = [
  {
    name: "Home",
    hyperlink: "/",
  },
  {
    name: "Over Atelier Stöpler",
    hyperlink: "/our-atelier",
  },
  {
    name: "Werkwijze",
    hyperlink: "/werkwijze",
  },
  {
    name: "Cursus",
    hyperlink: "/cursus",
  },
]

type Props = {
  openMenu: () => void
  isOpen: boolean
}

const Navigation = ({ openMenu, isOpen }: Props) => {
  return (
    <Container>
      <LeftSideContent>
        <Img src={atelier} />
        <NavigationItems>
          {menuLinks.map(({ name, hyperlink }) => (
            <Item
              key={name}
              to={hyperlink}
              activeStyle={{
                color: "black",
                fontWeight: 800,
                borderBottom: "2px solid gray",
              }}
            >
              {name}
            </Item>
          ))}
        </NavigationItems>
      </LeftSideContent>
      <CornerButton href="#contact-section">Contact</CornerButton>
      <MenuButtonContainer>
        <MenuButton handleMenu={openMenu} isOpen={isOpen} />
      </MenuButtonContainer>
    </Container>
  )
}

export default Navigation
