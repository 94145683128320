import styled from "styled-components"

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize["5xl"]};
`

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize["4xl"]};
`

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  color: ${({ theme }) => theme.colors.textPrimary} !important;
  text-decoration: none;
`

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
`

export const H5 = styled.h5`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize.xl};
`

export const H6 = styled.h6`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.fontSize.lg};
`

export const P = styled.p`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.fontSize.base};
  text-decoration: none;
`
