import * as React from "react"
import styled from "styled-components"
import MenuButton from "./MenuButton"
import { menuLinks } from "./Navigation"
import { Link } from "gatsby"
import atelierLogo from "../assets/photos/atelier-logo.png"

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 60px 0;
  background: rgba(255, 255, 255, 0.29);
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`

const MenuButtonContainer = styled.div`
  position: fixed;
  right: 30px;
  top: 10px;

  @media screen and ${({ theme }) => theme.breakpoints.mobileL} {
    right: 20px;
  }
`

const MenuItemsContainer = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.24);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
`

const MenuItem = styled(Link)`
  padding: 10px 0;
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  font-family: ${({ theme }) => theme.font.body};
  border: 2px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textGray};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
  }
`

const Logo = styled.img`
  height: 34px;
  width: auto;
  position: fixed;
  left: 30px;
  top: 13px;
`

type Props = {
  isOpen: boolean
  closeMenu: () => void
}

export const MenuScreen = ({ isOpen, closeMenu }: Props) => {
  return (
    <Container isOpen={isOpen}>
      <Logo src={atelierLogo} />
      <MenuButtonContainer>
        <MenuButton handleMenu={closeMenu} isOpen={isOpen} />
      </MenuButtonContainer>
      <MenuItemsContainer>
        {menuLinks.map(({ name, hyperlink }) => {
          return (
            <MenuItem
              key={name}
              to={hyperlink}
              onClick={closeMenu}
              activeStyle={{
                color: "black",
                fontWeight: 800,
              }}
            >
              {name}
            </MenuItem>
          )
        })}
      </MenuItemsContainer>
    </Container>
  )
}

export default MenuScreen
