export const crownRing =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/crown-ring.jpg"
export const cursus =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/cursus.jpg"
export const cursus1 =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/cursus1.jpg"
export const cursus2 =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/cursus2.jpg"
export const grapeNecklace =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/grape-necklace.png"
export const heroRing =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/hero-ring.jpg"
export const over = "https://atelierstopler.s3.eu-west-1.amazonaws.com/over.jpg"
export const photoAddress =
  "https://atelierstopler.s3.eu-west-1.amazonaws.com/photo-address.jpg"
